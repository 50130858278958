'use strict';

var dialog = require('./dialog');
var validator = require('./validator');

function handleSubmit() {
    $('.mcsubscribe-form').submit(function( event ) {
        event.preventDefault();

        var $form = $(this);
        $form.validate().form();

        if ($form.valid()) {
            var subscribeAction = $form.find('.leaderboard-button').attr('name');
            var formData = $form.serialize() + '&' + subscribeAction;
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'onEmail',
                email: $form.find('input[type=email]').val(),
                type: 'marketing'
            });
            $.ajax({
                url: $form.attr('action'),
                data: formData,
                method: $form.attr('method')
            })
           .done(function (data) {
               if (data.success) {
                   if (data.redirectURL !== undefined) {
                       location.href = data.redirectURL;
                   } else {
                       $('.mcsubscribe-form-container').addClass('d-none');
                       $('.mcsubscribe-success-message').removeClass('d-none');
                   }
               } else {
                   if(data.mcErrorMessage){
                       var mcErrorCode = JSON.parse(data.mcErrorMessage).errorcode;
                       if (mcErrorCode === 10006) {
                           $('.mcsubscribe-error-message .mc-email-error').removeClass('d-none');
                           $('.mcsubscribe-error-message .message').addClass('d-none');
                           $('.mcsubscribe-error-message .timeout-error-message').addClass('d-none');
                       } else {
                            $('.mcsubscribe-error-message .timeout-error-message').removeClass('d-none');
                       }
                   }
                   $('.mcsubscribe-form-container').addClass('d-none');
                   $('.mcsubscribe-error-message').removeClass('d-none');
               }

               // Scale modal to just fit message
               if ($form.closest('.ui-dialog').length) {
                   $form.closest('.ui-dialog')[0].style.setProperty('height', 'auto', 'important');
               }
           })
           .fail(function (xhr, textStatus) {
               $('.mcsubscribe-form-container').addClass('d-none');
               $('.mcsubscribe-error-message').removeClass('d-none');

               // Scale modal to just fit message
               if ($form.closest('.ui-dialog').length) {
                   $form.closest('.ui-dialog')[0].style.setProperty('height', 'auto', 'important');
               }
           });
        }

    });

    $('.mcsubscribe-close-button').on('click', function (e) {
        dialog.close();
    });

    $('.mcsubscribe-try-again-button').on('click', function (e) {
        e.preventDefault();

        // Scale modal back to normal height
        if ($(this).closest('.ui-dialog').length) {
            $(this).closest('.ui-dialog')[0].style.setProperty('height', '');
        }

        $('.mcsubscribe-form-container').removeClass('d-none');
        $('.mcsubscribe-error-message').addClass('d-none');
    });
    validator.init();
}

var emailsubscribe = {

    init: function() {
        $('.mcsubscribe-footer').on('click', function (e) {
            e.preventDefault();
            var dialogHeight = $(e.target).data('dialog-height') || 'auto';
            var dialogWidth = $(e.target).data('dialog-width') || 'auto';
            var dialogTitle = $(e.target).data('dialog-title');

            var url = $(e.currentTarget).attr('href');

            var dialogConfig = {
                options: {
                    dialogClass: 'c-leaderboard',
                    height: dialogHeight,
                    width: dialogWidth
                }
            };

            dialogConfig.url = url;

            if (dialogTitle) {
                dialogConfig.options.title = dialogTitle;
            }
            dialogConfig.callback = handleSubmit;

            dialog.open(dialogConfig);
        });
    }

};

module.exports = emailsubscribe;
