'use strict';

var ajax = require('./ajax'),
    util = require('./util');
/**
 * @function
 * @description Load details to a given gift certificate
 * @param {String} id The ID of the gift certificate
 * @param {Function} callback A function to called
 */
exports.checkBalance = function (id, pin, csrf, billingEmail, callback) {
    // load gift certificate details
    var url = util.appendParamsToUrl(Urls.giftCardCheckBalance,  {'giftCertificateID' : id,
    'pin':pin,
    'csrf_token':csrf,
    'billing_email':billingEmail,
    'source':'billing'});

    ajax.getJson({
        method: 'POST',
        url: url,
        callback: callback
    });
};
