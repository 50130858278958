'use strict';

const _ = require('lodash');

const stickyClass = 'has-sticky-header';
const $html = $('html');
const $body = $('body');
// const $header = $('#header');
// const $nav = $('#navigation');
const $topBanner = $('#header');
const $headerBanner = $('.header-banner');

let topBannerHeight = $topBanner.outerHeight();
let headerOffsetTop = $topBanner.offset().top || 0;
let headerBannerHidden = $headerBanner.css('display') === 'none';

const headerBannerVisibilityChanged = () => {
    const isHeaderBannerHidden = $headerBanner.css('display') === 'none';
    const visibilityChanged = isHeaderBannerHidden !== headerBannerHidden;
    headerBannerHidden = isHeaderBannerHidden;
    return visibilityChanged;
};

const doSticky = () => {
    if (headerBannerVisibilityChanged()) {
        resetSticky();
    } else {
        const scrollTop = Math.max($html.scrollTop(), $body.scrollTop());
        const isSticky = scrollTop >= headerOffsetTop;
        $html.toggleClass(stickyClass, isSticky);
        $body.css('padding-top', isSticky ? topBannerHeight : '');
    }
};

const resetSticky = () => {
    $html.removeClass(stickyClass);
    $body.css('padding-top', '');
    topBannerHeight = $topBanner.outerHeight();
    headerOffsetTop = $topBanner.offset().top || 0;
    doSticky();
};

module.exports = {
    init() {
        doSticky();
        $(window)
        .on('scroll', doSticky)
        .on('resize', function () {
            _.throttle(resetSticky, 100);
        });
    }
};
