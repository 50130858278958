'use strict';

var dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    page = require('../../page'),
    util = require('../../util'),
    Promise = require('promise'),
    _ = require('lodash');

/**
 * @description copy the category ID from the query string to the add to cart AJAX request
 *
 * @returns {String}
 */
function getCategoryID() {
    var qs = document.location.search.substring(1).split('&');
    var cgid = '';
    for (var i=0; i < qs.length; i++) {
        var pair = qs[i].split('=');
        if (decodeURIComponent(pair[0]) === 'cgid') {
            cgid = decodeURIComponent(pair[1]);
        }
    }
    return cgid;
}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize() + '&cgid=' + getCategoryID()
    })).then(function (response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            return response;
        }
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();
    var $form = $(this).closest('form');
    var atcQty = parseInt($form.find('input[name="Quantity"]').val(), 10);
    addItemToCart($form).then(function (response) {
        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.redirect(Urls.cartShow);
        } else {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass('sub-product-item')) {
                dialog.close();
            }
            minicart.show(response, true);

            let atcPliUuid = '';
            let $minicartContent = $('#mini-cart .mini-cart-content');
            if ($minicartContent.length) {
                atcPliUuid = $minicartContent[0].dataset['atcPliUuid'];
            }
            let itemsAddedTitle = '';
            if (atcQty > 1) {
                itemsAddedTitle = Resources.ITEMS_ADDED.replace('{0}', atcQty);
            } else if (atcQty === 1) {
                itemsAddedTitle = Resources.ITEM_ADDED;
            }
            var atcModalUrl = Urls.atcModal + '?uuid=' + atcPliUuid;
            $.ajax({
                type: 'GET',
                url: atcModalUrl,
                success: function (atcData) {
                    if (typeof atcData === 'string' && atcData.length) {
                        dialog.open({
                            html: atcData,
                            options: {
                                dialogClass: 'dialog-add-to-cart',
                                title: itemsAddedTitle,
                                open: function () {
                                    $('.btn-continue-shopping').on('click', function () {
                                        dialog.close();
                                    });
                                    $('.ui-widget-overlay').on('click', function () {
                                        dialog.close();
                                    });
                                }
                            }
                        });
                    } else {
                        // if atcModal is disabled, the expected response is an object with disabled: true
                        minicart.slide();
                    }
                },
                error: function () {
                    // treat an error as though the modal is disabled
                    minicart.slide();
                }
            });
        }
    }.bind(this));
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    Promise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
        });
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function () {
    $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    $('.add-to-cart').on('click', addToCart);
    $('#add-all-to-cart').on('click', addAllToCart);
};
