'use strict';

var bonusProductsView = require('./bonus-products-view');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    init: function () {
        this.$el = $('#mini-cart');
        this.$content = this.$el.find('.mini-cart-content');

        // events
        this.$el.find('.mini-cart-total').on('mouseenter', function () {
            if (this.$content.not(':visible')) {
                this.slide();
            }
        }.bind(this))
        .on('mouseleave', () => {
            timer.clear();
            timer.start(800, this.close.bind(this));
        });

        this.$content.on('mouseenter', function () {
            timer.clear();
        }).on('mouseleave', function () {
            timer.clear();
            timer.start(30, this.close.bind(this));
        }.bind(this));
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     * @param {Boolean} noAnimation Set to true to prevent the minicart from automatically sliding open when the content is set
     */
    show: function (html, noAnimation) {
        this.$el.html(html);
        this.init();
        if (!noAnimation) {
            this.slide();
        }
        bonusProductsView.loadBonusOption();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide: function () {
        timer.clear();
        // show the item
        this.$content.slideDown('slow');
        this.$el.addClass('is-open');
        // after a time out automatically close it
        timer.start(6000, this.close.bind(this));
    },
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        timer.clear();
        this.$content.slideUp(delay);
        this.$el.removeClass('is-open');
    }
};

module.exports = minicart;
