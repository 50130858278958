'use strict';

var quickview = require('./quickview'),
    _ = require('lodash');

function initQuickViewButtons() {
    $('body').on('mouseenter', '.tiles-container .js-has-quickview .product-image, .c-product-tile-carousel .js-has-quickview .product-image', function () {
        if ($(this).parents('#pdpMain').length || $(this).parents('.s-pdp-recommended-products').length) {
            // SPL-692 Preventing quickview button from being appended on carousel tiles on the PDP because of multiple unresolved issues in quickview when opened on PDP
            return;
        }

        var $qvButton = $('#quickviewbutton');
        if ($qvButton.length === 0) {
            $qvButton = $('<a id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '</a>');
        }
        var $link = $(this).find('.thumb-link');
        $qvButton.attr({
            'href': $link.attr('href'),
            'title': $link.attr('title')
        }).appendTo(this);
        $qvButton.on('click', function (e) {
            e.preventDefault();
            quickview.show({
                url: $(this).attr('href'),
                source: 'quickview'
            });
        });
    });
}

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}

//Separate function so that it can be put off via setTimeout
function swatchMouseLeave(e) {
    // Restore current thumb image
    var $tile = $(this).closest('.product-tile'),
        $thumb = $tile.find('.product-image .thumb-link img').eq(0);

    //False alarm.  Swatch list was exited without a swatch ever having been entered
    if(!$thumb.data('has-mouseentered')) {
        return;
    }

    var data = $thumb.data('current');

    $thumb.attr({
        src: data.front.src,
        alt: data.front.alt,
        title: data.front.title,
        srcset: data.front.srcset
    });

    $thumb.data('has-mouseentered', false);  //Now that we're done leaving, reset has-mouseentered for next time
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    initQuickViewButtons();
    gridViewToggle();

    $('.swatch-list').on('mouseleave', function() {
        _.debounce(swatchMouseLeave, 100).bind(this)();
    });

    $('.swatch-list .swatch').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.product-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));

        var data = $(this).find('img').filter(':first').data('thumb');
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var $thumbBack = $thumb.next();
        var currentAttrs = {
            src: data.front.src,
            alt: data.front.alt,
            title: data.front.title,
            srcset: data.front.srcset
        };

        var currentAttrsBack = {
            alt: data.back.alt,
            title: data.back.title

        };
        var currentDataBack = {
            src: data.back.src,
            srcset: data.back.srcset
        };
        $thumb.attr(currentAttrs);
        $thumbBack.attr(currentAttrsBack);
        $thumbBack.data(currentDataBack);

        for(var i in currentDataBack) {
            currentAttrsBack[i] = currentDataBack[i];
        }

        $thumb.data('current', {
            front: currentAttrs,
            back: currentAttrsBack
        });
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        $thumb.data('has-mouseentered', true); //Because swatch-list mouseleave can occur without having entered a swatch, we need to know if a swatch has been entered

        var $thumbBack = $thumb.next(),
            data = $(this).find('img').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img

        if (!current) {
            let currentData = {
                front: {
                    src: $thumb[0].src,
                    alt: $thumb[0].alt,
                    title: $thumb[0].title,
                    srcset: $thumb[0].srcset
                },
                back: {
                    src: '',
                    alt: '',
                    title: '',
                    srcset: ''
                },
            };
            if($thumbBack.length && $thumbBack[0]) {
                currentData.back = {
                    src: $thumbBack[0].src,
                    alt: $thumbBack[0].alt,
                    title: $thumbBack[0].title,
                    srcset: $thumbBack[0].scrset
                };
            }

            $thumb.data('current', currentData);
        }

        // Set the tile image to the values provided on the swatch data attributes
        //Don't need to update back image as user can't be over the swatch and the thumb at the same time
        $thumb.attr({
            src: data.front.src,
            alt: data.front.alt,
            title: data.front.title,
            srcset: data.front.srcset
        });

    });
    $('.product-swatches-all').on('click', function() {
        $(this).toggleClass('is-expanded')
            .siblings('.swatch-wrap:nth-child(n+6)').toggleClass('d-none');
    });

    $('.thumb-link').on('mouseenter', function() {
        let imgs = $(this).find('img'),
            back = imgs.last();
        if(imgs.length < 2) {
            return;
        }

        back.attr('src', back.data('src'));
        back.attr('srcset', back.data('srcset'));
        back.on('load', function() {
            $(this).parent('.thumb-link').addClass('thumb-link-active');
        });
    })
    .on('mouseleave', function() {
        let imgs = $(this).find('img'),
            back = imgs.last();
        if(imgs.length < 2) {
            return;
        }

        $(this).removeClass('thumb-link-active');
        back.attr('src', '');
        back.attr('srcset', '');
    });
}

exports.init = function () {
    initializeEvents();
};
