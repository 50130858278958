'use strict';

const counter = $('.moneris-timeout__counter');
const timeToTimeout = SitePreferences.MONERIS_HT_TIMEOUT;
const startTime = new Date();
const timeoutTime = new Date(startTime.getTime() + 60000 * timeToTimeout);
let countdownInterval;

function monerisTimer() {
    let now = new Date();

    if(!counter.length) {
        return;
    }
    if(now.getTime() >= timeoutTime.getTime()) {
        $('.moneris-timeout').addClass('moneris-timeout--expired');

        clearInterval(countdownInterval);
        return;
    }
    let timeRemaining = new Date(timeoutTime.getTime() - now.getTime());
    var locale = $('.locale-select .is-current').attr('data-locale');
    if (locale == "en_CA") {
        counter.html(timeRemaining.getMinutes() + ":" + timeRemaining.getSeconds());
    } else {
        counter.html(Resources.MONERIS_FR_TIME + " " + timeRemaining.getMinutes() + Resources.MONERIS_MIN + timeRemaining.getSeconds() + Resources.MONERIS_SEC);
    }
}

module.exports = {
    init() {
        monerisTimer();
        countdownInterval = setInterval(monerisTimer, 1000);
    }
};
