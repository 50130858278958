'use strict';

var util = require('../../util');

function initializeEvents() {

    util.initSelectCountry($('#RegistrationForm'));
    if($('#getStateCodeValFromOrderConfirmPage').length > 0) {
        $('#dwfrm_profile_address_states_state option[value="' + $('#getStateCodeValFromOrderConfirmPage').val() + '"]').attr('selected','selected');
    }
}

var orderconfirmation = {
    init: function () {
        initializeEvents();
    }
};

module.exports = orderconfirmation;