'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    dialog = require('../../dialog');


/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };
    if($('.pt_product-details').length && $('.pt_product-details').data('page_template')) {
        params.pageTemplate = $('.pt_product-details').data('page_template');
    }

    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }

            //Clear addthis so that share menu will be reinitialized when content is reloaded via ajax
            if(window.addthis) {
                window.addthis = null;
            }

            image.replaceImages();
            tooltip.init();

            // If TrueFit is added,
            if (typeof fitrec_reinitWidgets === "function") {
                fitrec_reinitWidgets();
            }
            var addToCart = require('./addToCart');// Re-initilaize Add to cart
            addToCart();
        }
    });
};


function openTrueFitSizeSampleDialog () {
    // Only open the dialog if the main widget exists and has not already been populated with a user
    // Only show the dialog up to once per browser session
    var $tfcProductMainWidget = $(".tfc-fitrec-product.tfc-product-main-widget:not(.tfc-product-suppressed)");
    if (!sessionStorage.getItem('truefit-modal-sizesample-shown') && $tfcProductMainWidget.length && $tfcProductMainWidget.find(".tfc-fitrec-result.tfc-nouser-responsive").length && typeof fitrec_reinitWidgets === 'function') {
        if ($tfcProductMainWidget.hasClass("tfc-product-sizesampler-enabled")) {
            dialog.open({
                url: Urls.truefitSizeSampler,
                options: {
                    dialogClass: 'truefit-modal-sizesampler',
                    open: function () {
                        sessionStorage.setItem('truefit-modal-sizesample-shown', '1');
                        fitrec_reinitWidgets();
                        $('.truefit-modal-sizesampler .tfc-fitrec-product').on('click', function () {
                            dialog.close();
                        });
                    }.bind(this)
                }
            });
        } else if ($tfcProductMainWidget.hasClass("tfc-product-autopopup-enabled") && $tfcProductMainWidget.find("button.tfc-popup-click-open").length) {
            sessionStorage.setItem('truefit-modal-sizesample-shown', '1');
            $tfcProductMainWidget.find("button.tfc-popup-click-open").trigger('click');
        }

    }
}

module.exports = function () {
    var $pdpMain = $('#pdpMain');

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        updateContent(this.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function () {
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());
    });

    // listen for custom event to open TrueFit size sampling dialog
    document.addEventListener('openTrueFitSizeSampleDialog', function(e) {
        openTrueFitSizeSampleDialog();
     });
};
