'use strict';

var _ = require('lodash'),
    inventory = require('./'),
    SessionAttributes = window.SessionAttributes;

var newLine = '\n';
var pdpStoreTemplate = function (store) {
    return [
        '<li class="store-list-item ' + (store.storeId === User.storeId ? ' selected' : '') + '">',
        '    <span class="store-address">' + store.address1 + ', ' + store.city + ' ' + store.stateCode +
        ', ' + store.postalCode + ':&nbsp;</span>',
        '    <span class="store-status ' + store.statusclass + '" data-status="' + store.statusclass + '">' + store.status + '</span>',
        '</li>'
    ].join(newLine);
};
var pdpStoresListingTemplate = function (stores) {
    if (stores && stores.length) {
        return [
            '<div class="store-list-pdp-container">',
            '    <ul class="store-list-pdp">',
            _.map(stores, pdpStoreTemplate).join(newLine),
            '    </ul>',
            (stores.length > 1 ? '    <button class="stores-toggle collapsed btn btn-link">' + Resources.SEE_MORE + '</button>' : ''),
            '</div>'
        ].join(newLine);
    }
};

var storesListing = function (stores) {
    // list all stores on PDP page
    if ($('.store-list-pdp-container').length) {
        $('.store-list-pdp-container').remove();
    }
    $('.availability-results').append(pdpStoresListingTemplate(stores));
};

var showStoreAvailability = function (storeId, pid) {
    $.ajax({
        url: Urls.showStoreAvailability,
        type: 'get',
        data: {storeId: storeId, pid: pid},
        success: function(data) {
            var $responseStoreInfo = $(data).filter('#store-info-container');
            if ($responseStoreInfo.length) {
                $('#product-content #store-info-container').html($responseStoreInfo);
            }
        }
    });
};
var productInventory = {
    setPreferredStore: function (storeId) {
        User.storeId = storeId;
        var pid = $('#pid').val();
        $.ajax({
            url: Urls.setPreferredStore,
            type: 'POST',
            data: {storeId: storeId, pid: pid},
            success: function(data) {
                $('#find-in-store').html(Resources.CHANGE_PREFERRED_STORE);
                if ($('#QuickViewDialog').length) {
                    window.location.href = window.location.href;
                }
                if (data[0].availability.length) {
                    if (data[0].availability === Resources.IN_STOCK) {
                        $('#add-to-cart').removeAttr('disabled');
                        $('#add-to-cart').prop('disabled', false);
                    } else {
                        $('#add-to-cart').attr('disabled', 'disabled');
                        $('#add-to-cart').prop('disabled', true);
                    }
                }
                showStoreAvailability(storeId, pid);
            }
        });
    },
    productSelectStore: function () {
        var self = this;
        inventory.getStoresInventory(this.pid).then(function (stores) {
            inventory.selectStoreDialog({
                stores: stores,
                selectedStoreId: User.storeId,
                selectedStoreText: Resources.PREFERRED_STORE,
                continueCallback: storesListing,
                selectStoreCallback: self.setPreferredStore
            });
        }).done();
    },
    init: function () {
        var $availabilityContainer = $('.availability-results'),
            self = this;
        this.pid = $('input[name="pid"]').val();

        $('#product-content').on('click', '.set-preferred-store', function (e) {
            e.preventDefault();
            // check if we have a preferred store already and aren't updating zip and skip to select store
            if (!User.zip && SessionAttributes.PREFERRED_STORE === null || typeof window.updateZip !== 'undefined' && window.updateZip === true) {
                inventory.zipPrompt(function () {
                    self.productSelectStore();
                });
            } else {
                self.productSelectStore();
            }
        });

        if ($availabilityContainer.length) {
            if (User.storeId) {
                inventory.getStoresInventory(this.pid).then(storesListing);
            }

            // See more or less stores in the listing
            $availabilityContainer.on('click', '.stores-toggle', function (e) {
                e.preventDefault();
                $('.store-list-pdp .store-list-item').toggleClass('visible');
                if ($(this).hasClass('collapsed')) {
                    $(this).text(Resources.SEE_LESS);
                } else {
                    $(this).text(Resources.SEE_MORE);
                }
                $(this).toggleClass('collapsed');
            });
        }

        // Pick up store CTAhanlding
        $('body').on('click', '#find-in-store', function (e) {
            e.preventDefault();
            var $tgt = $(e.currentTarget);
            var isQuickLookInStore = $tgt.data('quicklookinstore');
            if (isQuickLookInStore) {
            var productPageUrl = $tgt.data('url');
            var updatedPageUrl = productPageUrl + '?quicklookinstore=true';
            window.location.href = updatedPageUrl;
            }
        });
    }
};

module.exports = productInventory;
