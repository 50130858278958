'use strict';

var naPhone = /^(\+1)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    name: {
        us: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=`"\@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
        ca: /^[\A-Za-zÀ-úœ\-,.][^0-9_!¡?÷?¿/\\+=`"\@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
        fr: /^[\A-Za-zÀ-úœ\-,.][^0-9_!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
        it: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
        jp: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
        cn: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
        gb: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/
    },
    address: {
        us: /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|avenue|lane|road|boulevard|drive|street|Ave|Dr|Rd|Blvd|Ln|St|ave|dr|rd|blvdlLn|st)\.?/,
        ca: /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|avenue|lane|road|boulevard|drive|street|Ave|Dr|Rd|Blvd|Ln|St|ave|dr|rd|blvdlLn|st)\.?/,
        fr: /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|avenue|lane|road|boulevard|drive|street|Ave|Dr|Rd|Blvd|Ln|St|ave|dr|rd|blvdlLn|st)\.?/,
        it: /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|avenue|lane|road|boulevard|drive|street|Ave|Dr|Rd|Blvd|Ln|St|ave|dr|rd|blvdlLn|st)\.?/,
        jp: /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|avenue|lane|road|boulevard|drive|street|Ave|Dr|Rd|Blvd|Ln|St|ave|dr|rd|blvdlLn|st)\.?/,
        cn: /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|avenue|lane|road|boulevard|drive|street|Ave|Dr|Rd|Blvd|Ln|St|ave|dr|rd|blvdlLn|st)\.?/,
        gb: /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|avenue|lane|road|boulevard|drive|street|Ave|Dr|Rd|Blvd|Ln|St|ave|dr|rd|blvdlLn|st)\.?/
    },
    city: {
        us: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=`"\@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
        ca: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=`"\@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
        fr: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
        it: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
        jp: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
        cn: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
        gb: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    mmddyyyy: /^\d{2}\/\d{2}\/\d{4}$/,
    containsPOBox: /^((g\s*e\s*n\s*e\s*r\s*a\s*l\s*d\s*e\s*l\s*i\s*v\s*e\s*r\s*y\s*)|gd)|.*(?:P(?:ost(?:al)?)?[.\-\s]*(?:(?:O(?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d).*$|((g\s*e\s*n\s*e\s*r\s*a\s*l\s*d\s*e\s*l\s*i\s*v\s*e\s*r\s*y\s*)|gd)$/i,
    email:/^(([^<>()[\]\\.,;:!#$%&\s@\"]+(\.[^<>()[\]\\.,;:!#$%&\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
    }
};
/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var country = $(el).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.phone[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = isOptional || rgx.test($.trim(value));

    var $parent = $(el).parent();
    if (isValid || $parent.find('span.error').length) {
        //hide redundant ss sfcc error message
        $parent.find('.error-message.is-ss-error-message').hide();
        $(el).parents('div.form-row').removeClass('has-error');
    }
    return isValid;
};

/**
* @function
* @description Validates a given postal number against the countries phone regex
* @param {String} value The postal number which will be validated
* @param {String} el The input field
*/
var validatePostal = function (value, el) {
    var $elem = $(el);
    var isOptional = this.optional(el);
    var rgx;
    var countryCode;
    if ($elem.hasClass('ca-postal')) {
        countryCode = 'ca';
    } else if ($elem.hasClass('us-postal')) {
        countryCode = 'us';
    } else {
        var country = $elem.closest('form').find('.country');
        if (country.length) {
            countryCode = country.val().toLowerCase();
        }
    }
    if (countryCode) {
        rgx = regex.postal[countryCode];
    }
    if (!rgx) {
        // no regex pattern available
        return isOptional || value.length > 0;
    }

    // assist user with postal code formatting
    let formattedVal = $elem.val().toUpperCase().replace(/\s/g, '');
    if (formattedVal.length >= 3 && countryCode === 'ca') {
        formattedVal = formattedVal.substring(0, 3) + ' ' + formattedVal.substring(3);
    }
    value = formattedVal;
    $elem.val(value);

    var isValid = isOptional || rgx.test($.trim(value));

    var $parent = $elem.parent();
    if (isValid || $parent.find('span.error').length) {
        //hide redundant ss sfcc error message
        $parent.find('.error-message.is-ss-error-message').hide();
        $elem.parents('div.form-row').removeClass('has-error');
    }
    return isValid;
};

/**
* @function
* @description Validates a given postal number against the countries phone regex
* @param {String} value The postal number which will be validated
* @param {String} el The input field
*/
var validateDate = function (value, el) {
    if (value === '') {
        return this.optional(el);
    }

    //currently only supports JS default format of MM/dd/yyyy
    var date;
    if (regex.mmddyyyy.test(value)) {
        try {
            date = new Date(value);
            //identify if an invalid date like, February 30, was automatically changed
            let splitDate = value.split('/');
            if (date.getMonth() + 1 !== parseInt(splitDate[0], 10)) {
                date = null;
            }
            else if (date.getDate() !== parseInt(splitDate[1], 10)) {
                date = null;
            }
            else if (date.getFullYear() !== parseInt(splitDate[2], 10)) {
                date = null;
            }
        } catch (e) {
            date = null;
        }
    }
    var isValid = (date instanceof Date && !isNaN(date));
    return isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates an email confirmation
 * @param {String} value The email confirmation field which will be validated
 * @param {String} el The input field
 */
 var validatePasswordConfirm = function (value, el) {
    var firstPW = $(el).closest('form').find('.password:not(passwordconfirm)');
    var isValid = (firstPW && firstPW.val() === value);
    return isValid;
};

/**
 * @function
 * @description Validates an email
 * @param {String} value The email field which will be validated
 * @param {String} el The input field
 */
var validateEmail = function (value, el) {
    var isValid = regex.email.test($.trim(value));
    var $parent = $(el).parent();
    $parent.find('.error-message.c-form-row__error-message.is-ss-error-message').hide();
    $parent.find('.email').css('border-color', 'unset');
    return isValid;
 };

/**
 * @function
 * @description Validates an email confirmation
 * @param {String} value The email confirmation field which will be validated
 * @param {String} el The input field
 */
var validateEmailConfirm = function (values, el) {
    var email = $('.email').val();
    var $parent = $(el).parent();
    $parent.find('.error-message.c-form-row__error-message.is-ss-error-message').hide();
    $parent.find('.emailconfirm').css('border-color', 'unset');
    var emailConfirm = $('.emailconfirm').val();
    if(email === emailConfirm){
        return true;
    }else{
        return false;
    }
};

/**
 * @function
 * @description Validates an name
 * @param {String} value The name field which will be validated
 * @param {String} el The input field
 */
var validateName = function (value, el) {
    var country = $(el).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.name[country.val().toLowerCase()]) {
        return true;
    }
    var rgx = regex.name[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = isOptional || rgx.test($.trim(value));

    var $parent = $(el).parent();
    if (isValid || $parent.find('span.error').length) {
        //hide redundant ss sfcc error message
        $parent.find('.error-message.is-ss-error-message').hide();
        $(el).parents('div.form-row').removeClass('has-error');
    }
    return isValid;
};

/**
 * @function
 * @description Validates an name,address1,addess2,city
 * @param {String} value The name field which will be validated
 * @param {String} el The input field
 */
var validateInputFieldLimit = function (fieldValue, fieldNameOrId , fieldMaxLength) {
    var maxlengthOfTheField = $('.'+fieldNameOrId).attr('maxlength');
    if(typeof maxlengthOfTheField === "undefined"){
        maxlengthOfTheField = fieldMaxLength;
    }
    if(fieldValue.length > maxlengthOfTheField){
        return false;
    }
    return true;
};

var validateFirstNameinputFieldLimit = function (value) {
	return validateInputFieldLimit(value,'firstname' , Resources.CONSTANT_MAXLENTH_NAME)
};

var validateLastNameInputFieldLimit = function (value) {
	return validateInputFieldLimit(value,'lastname' , Resources.CONSTANT_MAXLENTH_NAME)
};

var validateAddress1InputFieldLimit = function (value) {
	return validateInputFieldLimit(value,'address1' , Resources.CONSTANT_MAXLENTH_ADDRESS)
};

var validateAddress2InputFieldLimit = function (value) {
	return validateInputFieldLimit(value,'address2', Resources.CONSTANT_MAXLENTH_ADDRESS)
};

var validateCityInputFieldLimit = function (value) {
	return validateInputFieldLimit(value,'city' , Resources.CONSTANT_MAXLENTH_CITY)
};

/**
 * @function
 * @description Validates an city
 * @param {String} value The city field which will be validated
 * @param {String} el The input field
 */
var validateCity = function (value, el) {
    var country = $(el).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.city[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.city[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates an address1 and address2
 * @param {String} value The address field which will be validated
 */
var validateAddress = function (value) {
    var AddressRgx = /[\\`"]/;
    var isValid = AddressRgx.test(value.trim());
    return !isValid;
};

/**
 * @function
 * @description Validates an address where PO boxes are forbidden
 * @param {String} value The address field which will be validated
 * @param {String} el The input field
 */
var validateAddressNoPO = function (value, el) {
    var rgx = regex.containsPOBox;
    var isValid = !(rgx.test($.trim(value)));

    var $parent = $(el).parent();
    if (isValid || $parent.find('span.error').length) {
        //hide redundant ss sfcc error message
        $parent.find('.error-message.is-ss-error-message').hide();
        $(el).parents('div.form-row').removeClass('has-error');
    }
    return isValid;
}

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add address1 validation method to jQuery validation plugin.
 * Text fields must have 'address1' css class to be validated as address
 */
// Intentionally disabled because the address validation regex blocks legitimate addresses
$.validator.addMethod('Addres1', validateAddress, Resources.VALIDATE_FIELD_INVALID);

/**
 * Add address2 validation method to jQuery validation plugin.
 * Text fields must have 'address1' css class to be validated as address
 */
// Intentionally disabled because the address validation regex blocks legitimate addresses
$.validator.addMethod('Addres2', validateAddress, Resources.VALIDATE_FIELD_INVALID);


/**
 * Add postal validation method to jQuery validation plugin.
 * Text fields must have 'postal' css class to be validated as postal
 */
$.validator.addMethod('postal', validatePostal, function (value, el) {
    var invalidPostal =  Resources.INVALID_POSTAL;
    var $elem = $(el);
    var countryCode;
    //Replace postal format in validation messages with US format message if selected country is US
    if ($elem.hasClass('us-postal')) {
        countryCode = 'us';
    } else {
        var country = $elem.closest('form').find('.country');
        if (country.length) {
            countryCode = country.val().toLowerCase();
        }
    }
    if (countryCode === 'us') {
        invalidPostal = invalidPostal.replace(Resources.VALIDATE_POSTAL_FORMAT_DEFAULT, Resources.VALIDATE_POSTAL_FORMAT_US);
    }
    return invalidPostal;
});

/**
 * Add confirm email' validation method to jQuery validation plugin.
 * Text fields must have 'emailconfirm' css class to be validated as email confirmation
 */
 $.validator.addMethod('passwordconfirm', validatePasswordConfirm, Resources.VALIDATE_PASSWORD_MISMATCH);

/**
 * Add email validation method to jQuery validation plugin.
 * Text fields must have 'email' css class to be validated as email
 */
$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);

/**
 * Add confirm email' validation method to jQuery validation plugin.
 * Text fields must have 'emailconfirm' css class to be validated as email confirmation
 */
$.validator.addMethod('emailconfirm', validateEmailConfirm, Resources.VALIDATE_EMAIL_MISMATCH);

/**
 * Add firstname validation method to jQuery validation plugin.
 * Text fields must have 'firstname' css class to be validated as firstname
 */
$.validator.addMethod('firstname', validateName, Resources.VALIDATE_FIRSTNAME_INVALID);

/**
 * Add lastname validation method to jQuery validation plugin.
 * Text fields must have 'lastname' css class to be validated as lastname
 */
$.validator.addMethod('lastname', validateName, Resources.VALIDATE_LASTNAME_INVALID);

/**
 * Add firstname validation method to jQuery validation plugin.
 * Text fields must have 'firstname' css class to be validated as firstname
 */
$.validator.addMethod('fName', validateName, Resources.VALIDATE_FIRSTNAME_INVALID);

/**
 * Add lastname validation method to jQuery validation plugin.
 * Text fields must have 'lastname' css class to be validated as lastname
 */
$.validator.addMethod('lName', validateName, Resources.VALIDATE_LASTNAME_INVALID);

/**
 * Add citie validation method to jQuery validation plugin.
 * Text fields must have 'citie' css class to be validated as city
 */
// Intentionally disabled city validation because it does not match back-end validation and was reported to be blocking legitimate cities
$.validator.addMethod('citie', validateCity, Resources.VALIDATE_FIELD_INVALID);

/**
 * Add firstname validation method to jQuery validation plugin.
 * Text fields must have 'firstname' css class to be validated as firstname max length validation.
 */
$.validator.addMethod('firstname', validateFirstNameinputFieldLimit, Resources.VALIDATE_MAXLIMIT);

/**
 * Add lastname validation method to jQuery validation plugin.
 * Text fields must have 'lastname' css class to be validated as firstname max length validation.
 */
$.validator.addMethod('lastname', validateLastNameInputFieldLimit, Resources.VALIDATE_MAXLIMIT);

/**
 * Add address1 and address2 validation method to jQuery validation plugin.
 * Text fields must have 'address1 address2' css class to be validated as firstname max length validation.
 */
$.validator.addMethod('address1', validateAddress1InputFieldLimit, Resources.VALIDATE_MAXLIMIT);

/**
 * Add address1 and address2 validation method to jQuery validation plugin.
 * Text fields must have 'address1 address2' css class to be validated as firstname max length validation.
 */
$.validator.addMethod('address2', validateAddress2InputFieldLimit, Resources.VALIDATE_MAXLIMIT);

/**
 * Add city validation method to jQuery validation plugin.
 * Text fields must have 'city' css class to be validated as firstname max length validation.
 */
$.validator.addMethod('city', validateCityInputFieldLimit, Resources.VALIDATE_MAXLIMIT);
/**
 * Add city validation method to jQuery validation plugin.
 * Text fields must have 'city' css class to be validated as city
 */
// Intentionally disabled city validation because it does not match back-end validation and was reported to be blocking legitimate cities
// $.validator.addMethod('city', validateCity, Resources.VALIDATE_FIELD_INVALID);

/**
 * Add date of birth validation method to jQuery validation plugin.
 * Text fields must have 'dob' css class to be validated as date
 */
 $.validator.addMethod('dob', validateDate, Resources.VALIDATE_FIELD_INVALID);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add AddressNoPO validation method to jQuery validation plugin.
 * Text fields must have 'addressnopo' css class to be validated as an address where PO BOX is forbidden
 */
 $.validator.addMethod('addressnopo', validateAddressNoPO, Resources.VALIDATE_ADDRESSNOPO_INVALID);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed


$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN),
    invalidAddressField: Resources.VALIDATE_FIELD_INVALID,
    passwordMismatch: Resources.VALIDATE_PASSWORD_MISMATCH,
    emailMismatch: Resources.VALIDATE_EMAIL_MISMATCH,
    postalFormatDefault: Resources.VALIDATE_POSTAL_FORMAT_DEFAULT,
    invalidfirstname: Resources.VALIDATE_FIRSTNAME_INVALID,
    invalidlastname: Resources.VALIDATE_LASTNAME_INVALID,
    invalidaddressnopo: Resources.VALIDATE_ADDRESSNOPO_INVALID
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;
