'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    util = require('../../util'),
    moneris = require('../../../../../lib/moneris/int_moneris/cartridge/js/pages/checkout/moneris'),
    adyenCheckout = require('../../adyen-checkout');

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
}

/**
* @function
* @description Changes the payment type or issuerId of the selected payment method
* @param {String, Boolean} value of payment type or issuerId and a test value to see which one it is, to which the payment type or issuerId should be changed to
*/
function updatePaymentType(selectedPayType, issuerType) {
    if(issuerType){
        $('#dwfrm_adyPaydata_issuer').val(selectedPayType);
    }
    else{
        $('input[name="brandCode"]').removeAttr('checked');
        $('input[value=' + selectedPayType + ']').prop('checked', 'checked');
    }
    // if the payment type has hidden fields reveal it
    $('#component_' + selectedPayType).show();
    formPrepare.validateForm();
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFields(data);
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID, addClass) {
    var $paymentMethods = $('.payment-method');
    if (addClass) {
        $paymentMethods.removeClass('payment-method-expanded');
    }

    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    $selectedPaymentMethod.addClass('payment-method-expanded');

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

    formPrepare.validateForm();
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $checkoutForm = $('.checkout-billing');
    var $continueSelector = $('button[name$="billing_save"]');
    var $addGiftCert = $('#add-giftcert');
    var $checkGiftCert = $('#check-giftcert');
    var $giftCertCode = $('input[name$="_giftCertCode"]');
    var $pin = $('input[name$="_pin"]');
    var $gcCSRF = $('input[name="gc_csrf_token"]');
    var $billingEmail = $('input[name$="_email_emailAddress"]');
    var $addCoupon = $('#add-coupon');
    var $couponCode = $('input[name$="_couponCode"]');
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
    var $payType = $('[name="brandCode"]');
    var $paymentMethodsList = $('#paymentMethodsList');
    var $issuer = $('.issuer');
    var selectedPayType = $payType.find(':checked').val();

    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: $continueSelector
    });

    // After gift card added and refreshed pull the data from storage session to repopulate (what user had left before applying GC )
    var sessionBillingDetails = JSON.parse(sessionStorage.getItem("billingForm"));
    for (const ID in sessionBillingDetails) {
        if (ID !== 'dwfrm_giftcert_redemption_giftCertCode' && ID !== 'dwfrm_giftcert_redemption_pin') {
            $("#" + ID).val(sessionBillingDetails[ID])
        }
    }

    // default payment method to 'CREDIT_CARD'
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD', true);
    $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
        updatePaymentMethod($(this).val(), false);

        if ($(this).val() == 'Adyen' && $payType.length > 0) {
            // set payment type of Adyen to the first one
            updatePaymentType((selectedPayType) ? selectedPayType : $payType[0].value, false);
        } else {
            $payType.removeAttr('checked');
        }
        if ($(this).val() == 'PayPal') {
            if ($paymentMethodsList.find('[name="brandCode"]:checked').length > 0) {
                var $payTypeValue = $paymentMethodsList.find('[name="brandCode"]:checked').value;
                updatePaymentType((selectedPayType) ? selectedPayType : $payTypeValue, false);
                $paymentMethodsList.find('[name="brandCode"]:checked').removeAttr('checked');
                adyenCheckout.resetPaymentMethod();
            }
        } else {
            $selectPaymentMethod.find('[type="radio"]:checked').removeAttr('checked');
        }
    });

    // select credit card from list
    $('#creditCardList').on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {return;}
        populateCreditCardForm(cardUUID);

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });

    $('#check-giftcert').on('click', function (e) {
        e.preventDefault();
        var $balance = $('.balance');
        $balance.html('');

        var $error = $('.giftcert-error');
        $error.html('');

        if ($giftCertCode.length === 0 || $giftCertCode.val().trim().length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING);
            return;
        } else if ($pin.length === 0 || $pin.val().trim().length === 0) {
            $error.html(Resources.GIFT_PIN_MISSING);
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), $pin.val(), $gcCSRF.val(), $billingEmail.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                return;
            }
            if(data.giftCertificate.error) {
                $balance.html('');

                if($error.length) {
                    var msg = '';
                    msg = data.giftCertificate.errormsg;
                    if (msg === 'Invalid user ID/pswd') {
                        msg = Resources.GIFT_CERT_INVALID_NUMBER;
                    }
                    if (msg === 'Cert not exist') {
                        msg = Resources.GIFT_CERT_INVALID_NUMBER;
                    }
                    $error.html(msg);
                }
                else {
                    $('.gift-cert-balance-submit').after(`<div class="form-row error">${data.giftCertificate.errormsg}</div>`);
                }
            } else {
                $error.html('');
                $balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance).removeClass('error').addClass('success');
            }
        });
    });

    $addGiftCert.on('click', function (e) {
        e.preventDefault();

        var $balance = $('.balance');
        $balance.html('');

        var code = $giftCertCode.val(),
            pin = $pin.val(),
            $error = $checkoutForm.find('.giftcert-error');
            $error.html('');

        if (code === null || code.trim().length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING);
            return;
        } else if (pin === null || pin.trim().length === 0) {
            $error.html(Resources.GIFT_PIN_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {
            'giftCertificateID': code,
            'pin': pin,
            'source': 'billing',
            'csrf_token': $gcCSRF.val(),
            'billing_email': $billingEmail.val(),
            'action': 'apply',
            'format': 'ajax'});

        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (data.giftCertificate.error) {
                msg = data.giftCertificate.errormsg;
                if (msg === 'GIFT_CERT_NO_AMOUNT') {
                    msg = Resources.GIFT_CERT_NO_AMOUNT;
                } else if (msg === 'GIFT_CERT_MISSING') {
                    msg = Resources.GIFT_CERT_MISSING;
                } else if (msg === 'GIFT_PIN_MISSING') {
                    msg = Resources.GIFT_PIN_MISSING;
                } else if (msg === 'GIFT_CERT_MAX_NUMBER_LIMIT') {
                    msg = Resources.GIFT_CERT_MAX_NUMBER_LIMIT;
                } else if (msg === 'GIFT_CERT_MAX_VALUE_LIMIT') {
                    msg = Resources.GIFT_CERT_MAX_VALUE_LIMIT;
                }
                if (msg === 'Invalid user ID/pswd') {
                    msg = Resources.GIFT_CERT_INVALID_NUMBER;
                }
                if (msg === 'Cert not exist') {
                    msg = Resources.GIFT_CERT_INVALID_NUMBER;
                }
                if (msg === 'Invalid user ID/pswd') {
                    msg = Resources.GIFT_CERT_INVALID_NUMBER;
                }
                if (msg === 'Cert not exist') {
                    msg = Resources.GIFT_CERT_INVALID_NUMBER;
                }
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            } else {

                // Save filled form billing in the session - to be used to repopulate the fields on reloead after applying giftcard
                window.sessionStorage.setItem("billingForm",formToString($("#dwfrm_billing")));// Save filled form in the session - to be used to repopulate the fields on reloead after applying giftcard
                window.location.assign(Urls.billing);
            }
        });
    });

    $addCoupon.on('click', function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error'),
            code = $couponCode.val();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success && data.baskettotal === 0) {
                window.location.assign(Urls.billing);
            }
        });
    });


    // trigger events on enter
    $couponCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });
    $giftCertCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });

    var cancelButtonClick = false;
    $('button.cancel').click(function() {
        cancelButtonClick = true;
    });
    var $monerisTokenInput = $('#dwfrm_billing_paymentMethods_creditCard_monerisToken');
    if ($monerisTokenInput.length) {
        $monerisTokenInput.val(null);
    }
    $checkoutForm.on('submit', function (e) {
        if (!$(this).valid()) {
            return true;
        }
        if (cancelButtonClick) {
            return true;
        }
        if ($('input#is-CREDIT_CARD').is(':checked') && !$('input[name$="monerisToken"]').val() && $('#monerisFrame').length) {
            e.preventDefault();
            return moneris.doMonerisSubmit();
        }
        return true;
    });

    // listener moved from moneris.js
    if (window.addEventListener) {
        window.addEventListener("message", function(e) {
            moneris.respMsg(e);
        }, false);
    } else if (window.attachEvent) {
        window.attachEvent("onmessage", function(e) {
            moneris.respMsg(e);
        });
    }


    $('label[for="hardgoods-terms-and-conditions"] a').on('click', function(e) {
        e.preventDefault();
        var dialog = require('../../dialog');
        dialog.open({
            url: Urls.hardgoodsTermsAndConditions
        });
    });
    $('#hardgoods-terms-and-conditions').on('change', function(e) {
        var $placeOrderBtn = $('.js-place-order-button');
        if(this.checked) {
            $placeOrderBtn.removeAttr('disabled');
        } else {
            $placeOrderBtn.attr('disabled', 'disabled');
        }
    });

    if (SitePreferences.ADYEN_SF_ENABLED) {
        adyenCheckout.initBilling();
    }

    $payType.on('change', function() {
        $('#selectedIssuer').val("");
        $issuer.hide();
        $('.checkoutComponent').hide();
        $('#component_' + $(this).val()).show();
        if($(this).siblings( ".issuer").length > 0){
            $('#selectedIssuer').val($(this).siblings( ".issuer" ).val());
            $(this).siblings('.issuer').show();
        }
    });

    util.initSelectCountry($checkoutForm);

    function formToString(filledForm) {
        var formObject = {};    
        filledForm.find("input, select, textarea").each(function() {
            if (this.id) {
                var elem = $(this);
                if (elem.attr("type") == 'checkbox' || elem.attr("type") == 'radio') {
                    formObject[this.id] = elem.attr("checked");
                } else {
                    formObject[this.id] = elem.val();
                }
            }
        });
        var formString = JSON.stringify(formObject);
        return formString;
    }

};
