/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

require('./polyfills');

var $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('jquery-ui');
require('jquery-validation');
require('svg4everybody')();
require('slick-carousel');

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    searchsuggest = require('./searchsuggest'),
    tooltip = require('./tooltip'),
    qty = require('./qty'),
    aToggle = require('./a-toggle'),
    aScrollTo = require('./a-scroll-to'),
    tabToggle = require('./tab-toggle'),
    recentlyViewed = require('./recently-viewed'),
    accordion = require('./c-accordion'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    stickyHeader = require('./sticky-header'),
    stickyElem = require('./sticky-elem'),
    consentTracking = require('./consentTracking'),
    nav = require('./nav'),
    productTile = require('./product-tile'),
    handleScrollBar = require('./handle-scroll-bar'),
    emailSubscribe = require('./emailsubscribe');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
consentTracking.init();

function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('.form-field-tooltip a').click(function(e) {
        e.preventDefault();
    });

    $('body').on('click','.locale-select-link',function(e){
        e.preventDefault();

        var link = $(this);

        var locale = link.data('locale');

        setCookie('user-language',locale,1800);

        window.location = link.attr('href');
    });

    $(window).on('load',function () {
        $('.Guestcheckoutcontent-body').hide();
        $('.Guestcheckout-content').hide();
        $('.signindiv').addClass('active');
     });

     $('body').on('click','.guestsignlink',function (e) {
        e.preventDefault();
        $('.signin-sub-body').hide();
        $('.Guestcheckoutcontent-body').show();
        $('.Guestcheckout-content').show();
        $('.guestsignlink').addClass('active');
        $('.signindiv').removeClass('active');
        $('html, body').animate({
            scrollTop: 0
        }, 200);
    });


     $('body').on('click','.signindiv',function (e) {
         e.preventDefault();
         $('.Guestcheckoutcontent-body').hide();
         $('.signin-sub-body').show();
         $('.Guestcheckout-content').hide();
         $('.signindiv').addClass('active');
         $('.guestsignlink').removeClass('active');
     });

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        })
        // global dialog close class
        .on('click', '.js-dialog-close', e => {
            e.preventDefault();
            dialog.close();
        });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $('.header-search');
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    $(window).scroll(function() {
        if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
            $('#return-to-top').fadeIn(200);    // Fade in the arrow
        } else {
            $('#return-to-top').fadeOut(200);   // Else fade out the arrow
        }
    });
    $('#return-to-top').click(function() {      // When arrow is clicked
        $('body,html').animate({
            scrollTop : 0                       // Scroll to top of body
        }, 500);
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    var idMatcher = new RegExp('^(#|\\.)');
    $('.privacy-policy, .js-dialog-link').on('click', function (e) {
        e.preventDefault();
        var dialogHeight = $(e.target).data('dialog-height') || 'auto';
        var dialogWidth = $(e.target).data('dialog-width') || 800;
        var dialogTitle = $(e.target).data('dialog-title');

        var url = $(e.currentTarget).attr('href');

        var dialogConfig = {
            options: {
                height: dialogHeight,
                width: dialogWidth
            }
        };

        if (idMatcher.test(url) && $(url).length) {
            // send html from target ID if exists
            dialogConfig.html = $(url).html();
        } else {
            // otherwise assume href is valid URL
            dialogConfig.url = url;
        }

        if (dialogTitle) {
            dialogConfig.options.title = dialogTitle;
        }

        dialog.open(dialogConfig);

    });


    $('.consent-tracking-policy').on('click', function (e) {
        e.preventDefault();
        consentTracking.show();
    });
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator'),
    orderconfirmation: require('./pages/checkout/orderconfirmation')
};

function renderLocationPopup(){
    if( $('#targeted-popup-content').length){

        if( document.cookie.indexOf('locationTargeted') === -1 ) {
            var dialog = require('./dialog');
            var dialogWrapper = $('#targeted-popup');
            var dialogHeight = dialogWrapper.find('#targeted-popup-content').data('dialog-height') || 'auto';
            var dialogWidth = dialogWrapper.find('#targeted-popup-content').data('dialog-width') || 800;

            var dialogConfig = {
                html: dialogWrapper.html(),
                options: {
                    height: dialogHeight,
                    width: dialogWidth,
                    autoOpen: true
                }
            };
            var cookieExpiryDays = SitePreferences.TARGETED_COOKIE_EXPIRY;
            dialog.open(dialogConfig);
            setCookie('locationTargeted', '1', cookieExpiryDays);
        }
    }
}

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert alert alert-warning').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }

        initializeDom();
        initializeEvents();

        var GTM = require('../../../lib/google/int_google/cartridge/js/GTM');

        // init specific global components
        stickyHeader.init();
        stickyElem.init();
        handleScrollBar.init();
        countries.init();
        tooltip.init();
        qty.init();
        aToggle.init();
        aScrollTo.init();
        tabToggle.init();
        recentlyViewed.init();
        accordion.init();
        minicart.init();
        validator.init();
        nav.init();
        productTile.init();
        emailSubscribe.init();

        // initialize any data attribute sliders.
        $('[data-slick]').slick();

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }
        window.sgDialog = require('./dialog');
        window.sgUtil = require('./util');
        GTM.init();

        if(ns === 'storefront'){
            renderLocationPopup();
        }

    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
});
