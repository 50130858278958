'use strict';

var _ = require('lodash');

var ns = 'js-accordion';
var ns2 = 'js-sticky-elem';

function setJsHeight() {
    $(`.${ns}__body--js-height`).each(function(i, j) {
        let elemHeight = 0;

        //If the element has a cutoff, the content's current height may not match its full height
        //So get content inside of elems directly inside accordion body
        if($(j).hasClass('has-cutoff')) {
            $(j).children().each(function(k, l) {
                elemHeight += l.scrollHeight;
            });
        }
        else {
            elemHeight = $(j)[0].scrollHeight;
        }
        let $customMenuSearchInput = $(j).find('.custom-menu-search-input');
        if ($customMenuSearchInput.length) {
            // Add 40px height for any custom menu elements
            elemHeight += $customMenuSearchInput.length * 40;
        }
        elemHeight += $(j).outerHeight() - $(j).height();  //Get total size of vertical paddings and add them to max height

        //Check if placeholder is computed to check for var support
        if(getComputedStyle(document.body).getPropertyValue('--elem-height')) {
            j.style.setProperty('--elem-height', elemHeight + 'px');
        }
        //Fallback if css variable not supported
        else {
            $(j).css('max-height', elemHeight + 'px');
        }
    });
    toggleMobilePageAdjustments();
}

function toggleMobilePageAdjustments() {
    const $selectedMblRefinementsFilter = $('.mobile-refinement-filter__c-accordion__item.is-expanded');

    if ($selectedMblRefinementsFilter.length && $selectedMblRefinementsFilter.find('#mobile-refinements').length) {
        // Hide header-banner when mobile refinements are displayed.
        $('.header-banner, div.embeddedServiceHelpButton').addClass('js-mobile-hide');

        // Disable body scrolling in background of menu
        $('body').addClass('js-mobile-scroll-disable');
    } else {
        $('.header-banner, div.embeddedServiceHelpButton').removeClass('js-mobile-hide');
        $('body').removeClass('js-mobile-scroll-disable');
    }
}

function markSelectedRefinements() {
    const $allRefinements = $(`.${ns2} div.refinement`);
    const $selectedRefinements = $(`.${ns2} div.refinement:has(.c-accordion__body li.selected)`);
    $allRefinements.not($selectedRefinements).removeClass('has-selected-refinement');
    $selectedRefinements.addClass('has-selected-refinement');
}

module.exports = {

    init() {
        markSelectedRefinements();
        $('body')
        .on('click', `.${ns}__target`, e => {
            if (!$(e.target).is('a')) {
                e.preventDefault();
                const $tgt = $(e.currentTarget);
                const $mblRefinements = $tgt.parents('#mobile-refinements');
                const $tgtItem = $tgt.closest(`.${ns}__item`);
                $tgtItem.toggleClass('is-expanded');
                toggleMobilePageAdjustments();

                // record the last toggled refinement class so that we can re-expand it on mobile nav refresh
                let $refinementParentDiv = $tgtItem.parent('div.refinement');
                if ($refinementParentDiv.length) {
                    let $lastMarkedClass = $refinementParentDiv.attr('class');
                    $lastMarkedClass = $lastMarkedClass.trim();
                    // replace all multi-spaces
                    $lastMarkedClass = $lastMarkedClass.replace(/ +/g, ' ');
                    // replace single spaces with periods
                    $lastMarkedClass = $lastMarkedClass.replace(/ /g, '.');
                    $lastMarkedClass = '.' +  $lastMarkedClass;
                    $lastMarkedClass = $lastMarkedClass.replace('.has-selected-refinement', '');
                    window.sessionStorage.setItem('ca_refinementNav_lastMarked', $lastMarkedClass);
                }


                if ($mblRefinements.length > 0) {
                    $mblRefinements.find(`.${ns}__item`).not($tgtItem).removeClass('is-expanded');
                } else {
                    $tgt.closest(`.${ns}`).find(`.${ns}__item`).not($tgtItem).removeClass('is-expanded');
                }
            }
        })
        .on('click', `.${ns}__see-all`, e => {
            const $tgt = $(e.currentTarget);

            $tgt.closest('.c-accordion__body').toggleClass('c-accordion__see-all');
        })
        .on('click', `.${ns2} a`, e => {
            markSelectedRefinements();
        });

        $.expr[":"].Contains = function(n, i, m) {
           return jQuery(n).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
        };

        $('body').on('click','.category-refinement a', function() {
            var breadCrumb = $('.breadcrumb-element').first().text();
            breadCrumb = breadCrumb.replace(/(\r\n|\n|\r)/gm, "");
            if (breadCrumb == 'Our Brands') {
                sessionStorage.setItem('Brand','true');
            }
        });
        
        $('body').ready(function() {
            var loadedCat = $('.category-header').text();
            var doesExist = $('.brand').find('li a:Contains("'+loadedCat+'")');
            var breadCrumb = $('.breadcrumb-element').first().text();
            breadCrumb = breadCrumb.replace(/(\r\n|\n|\r)/gm, "");
            var searchValue = 'false';
            if (breadCrumb.includes('Your search phrase was')) {
                searchValue = 'true';
            }
            var brandCheck = sessionStorage.getItem('Brand');
            if (doesExist && brandCheck == 'true' && searchValue == 'false') {
                $('.brand').css('display','none');
            } else {
                $('.brand').css('display','block');
            }
        });
        
        $('body').on('click keypress', '.menu-category a', function(e) {
            if (e.which == 13 || e.type === 'click') {
                sessionStorage.removeItem('Brand');
            }
        });
        
        $('body').on('click keypress', '.header-search__submit', function(e) {
            if (e.which == 13 || e.type === 'click') {
                sessionStorage.removeItem('Brand');
            }
        });
        
        $('body').on('keyup touchend','.searchBrand', function() {
            if( $(this).val.length > 0) {
                $('.brand').find('li a:not(:Contains("'+$(this).val()+'"))').closest('li').hide();
                $('.brand').find('li a:Contains("'+$(this).val()+'")').closest('li').show();
            }
        });

        setJsHeight();
        $(window).on('resize', _.throttle(e => {
            setJsHeight();
        }, 100));
    },
    setJsHeight: setJsHeight,
    markSelectedRefinements: markSelectedRefinements
};
