'use strict';

var brands = require('../../../../app_spl/cartridge/js/brands'),
    compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    progress = require('../progress'),
    util = require('../util'),
    viewportIs = require('../viewport-is'),
    _ = require('lodash'),
    stickyElem = require('../sticky-elem'),
    cAccordion = require('../c-accordion');
var GTM = require('../../../../lib/google/int_google/cartridge/js/GTM');

function createCookie(name, value) {
    var date = new Date();
    date.setTime(date.getTime()+(60*60*1000));
    var expires = "; expires="+date.toGMTString();

    document.cookie = name+"="+value+expires+"; path=/";
}

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="loading"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');
    if (loadingPlaceHolder.length === 1) {
        var buttonClickedID = loadingPlaceHolder.attr('data-button-associated');
        $('#' + buttonClickedID).remove();
        var currentpage = loadingPlaceHolder.attr('data-currentpage');
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        progress.show($('.infinite-scroll-placeholder-' + currentpage));
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');

        var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
        progress.show($('.infinite-scroll-placeholder'));
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');
        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            var infiniteScrollDiv = $(html).filter('.infinite-scroll-div');
            var products = $(html).find('li.grid-tile');
            $('#search-result-items').append(products);
            $('.infinite-scroll-div').replaceWith(infiniteScrollDiv);
        };
        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // update UI
                progress.hide();
                fillEndlessScrollChunk(response);
                if ($(response).find('.infinite-scroll-placeholder')) {
                    var pageSize = $(response).filter('.infinite-scroll-div').attr('data-pagesize');
                    var $productTileWrapper = $('#search-result-items');
                    var totalProducts = $productTileWrapper.find('.grid-tile').length;
                    if (typeof pageSize !== 'undefined' || pageSize !== null || pageSize !== '') {
                        pageSize = parseInt(pageSize);
                        var newUrl;
                        var currentUrl = window.location.href;
                        newUrl = util.removeParamFromURL(currentUrl, 'sz');
                        newUrl = util.appendParamToURL(newUrl, 'sz', pageSize);
                        history.pushState({}, null, newUrl);
                        updateProgressBar();
                        progress.hide();
                    }
                }
                productTile.init();
            },
            error: function (response) {
                progress.hide();
            }
        });
    }
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url, popped, scrollTop=false, areRefsExpanded=false) {
    if (!popped && (!url || url === window.location.href)) {
        return;
    }
    progress.show($('.search-result-content'));
    $('#main').load(util.appendParamToURL(util.appendParamToURL(url, 'refsExpanded', areRefsExpanded), 'format', 'ajax'), function () {
        var breadCrumb = $('.breadcrumb-element').first().text();
        breadCrumb = breadCrumb.replace(/(\r\n|\n|\r)/gm, "");
        if (breadCrumb.includes('Your search phrase was')) {
            $('.brand .c-accordion__item').removeClass('hide');
        }
        compareWidget.init();
        productTile.init();
        updateProgressBar();
        brands.init();
        progress.hide();
        $('#mobile-refinements').remove();
        placeRefinements();

        if (scrollTop) {
            $('html, body').animate({
                scrollTop: 0
            }, 300);
        }

        if (!popped) {
            history.pushState(undefined, '', url);
        }

        // Google tag manager reattach
        GTM.reinit();

        stickyElem.resetSticky();
        cAccordion.setJsHeight();
    });
}

function updateProgressBar() {
    var pagesize = $('.progress-bar').attr('data-pagesize');
    var maxsize = $('.progress-bar').attr('data-pagemaxsize');
    var percentage = Math.round(((pagesize/maxsize) * 100));
    $('.progress-percent').css('width', percentage + '%');
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $('#main');
    updateProgressBar();
    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });

    // handle toggle refinement blocks
    $main.on('click', '.refinement h3', function () {
        $(this).toggleClass('expanded')
        .siblings('ul').toggle();
    });

    // handle events for updating grid
    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a', function (e) {
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parents('.pt_content-search-result').length > 0 || $(this).parent().hasClass('unselectable')) {
            return;
        }
        e.preventDefault();
        var scrollTop = false;
        if ($(e.currentTarget).is('.search-result-options--bottom a')) {
            scrollTop = true;
        }
        var refsExpanded = !($(this).parents('.breadcrumb-refinement-value').length > 0) && $(this).closest('.pagination').length === 0;
        updateProductListing(this.href, false, scrollTop, refsExpanded);
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not("#quickviewbutton")', function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // GTM events on refinements
    $main.on('click', '.c-accordion ul li', function(){
        var dataLayer = window.dataLayer;
        var _dataLayer = {
            'event' : 'categoryRefinement',
            'refinementOption' : $(this).parents('.c-accordion__item').eq(0).find('.c-accordion__header').text().trim(),
            'value' : $(this).text().trim()
        };

        dataLayer.push(_dataLayer);
    });

    // GTM events on sort dropdown
    $main.on('change', '#grid-sort-header, #grid-sort-footer', function(){
        var dataLayer = window.dataLayer;
        var _dataLayer = {
            'event' : 'sortRefinement',
            'sortOption' : "Sort By",
            'value' : $(this).find('option:selected').text().trim()
        };

        dataLayer.push(_dataLayer);
    });

    // GTM events on infinite scroll trigger
    $main.on('click', '.infinite-scroll-placeholder button', function(){
        var dataLayer = window.dataLayer;
        var _dataLayer = {
            'event' : 'sortRefinement',
            'sortOption' : 'Show More Products',
            'value' : 'Show More Products'
        };

        dataLayer.push(_dataLayer);
    });

    // GTM events on sortby
    $main.on('click', '.grid-sort-header option', function() {
        window.dataLayer = window.dataLayer || [];
        var dataLayer = window.dataLayer;

        var filterValue = $(this).text().replace(/[\n\r]/g, '');

        dataLayer.push({
            "event": "sortBy", "filterValue": filterValue
        });
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        updateProductListing($(this).find('option:selected').val(), false, false, $('.mobile-refinement-filter__c-accordion__item').hasClass('is-expanded'));
    })
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
        }
    });

    $('body').on('click', '.infinite-scroll-placeholder button', function(e){
        var productsLoaded = $('.grid-tile').length;
        var data = {
            format : 'ajax',
            infinite : true
        };

        var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
        progress.show($('.infinite-scroll-placeholder'));
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');

        $.ajax({
            url: $(this).data('grid-url'),
            dataType: 'html',
            data: data
        })

        .done(function(data){
            var $productTileWrapper = $('#search-result-items');
            var totalProducts = $productTileWrapper.find('.grid-tile').length;
            $('.result-range').text('1-' + totalProducts);
            progress.hide();
            GTM.reinit();
        });
    });

}

function placeRefinements() {
    if (viewportIs(['mobile','tablet'])) {
        if(!$('#mobile-refinements').length) {
            var $src = $('.js-placerefinements__src');
            var dest = '.js-placerefinements__dest';
            var $dest = $(dest);
            var lastMarked = window.sessionStorage.getItem('ca_refinementNav_lastMarked');
            var $markedRefinement = lastMarked && $src.length ? $src.find(lastMarked + ' > .c-accordion__item') : null;
            if ($markedRefinement && $markedRefinement.length) {
                if (!$markedRefinement.hasClass('is-expanded')) {
                    $markedRefinement.addClass('is-expanded');
                }
                $src.find('.refinement').not(lastMarked).find('.is-expanded').removeClass('is-expanded');
            } else {
                $src.find('.is-expanded').removeClass('is-expanded');
            }
            $dest.html('<div id="mobile-refinements">' + $src.html() + '</div>');
            cAccordion.markSelectedRefinements();
            cAccordion.setJsHeight();
        }
    }
    else if($('#mobile-refinements').length) {
        $('#mobile-refinements').remove();
    }
}

exports.init = function () {
    var product = require('./product');
    product.initializeEvents();

    compareWidget.init();
    $(document).on('click','.infinite-scroll-div button', function(){
        infiniteScroll();
    });


    // if auto infinite scroll is enabled, detect if infinite scroll button is nearly scrolled into view and automatically trigger infinite scroll
    function autoInfiniteScroll() {
        var $infiniteScrollDiv = $('.infinite-scroll-div.auto-infinite-scroll');
        if (!$infiniteScrollDiv.length) {
            return;
        }
        var $infiniteScrollPlaceholder = $infiniteScrollDiv.find('.infinite-scroll-placeholder');
        var $infiniteScrollButton = $infiniteScrollDiv.find('button');
        if ($infiniteScrollButton.length && $infiniteScrollPlaceholder.length && $infiniteScrollPlaceholder.attr('data-loading-state') === 'unloaded') {
            var buttonOffset = $infiniteScrollButton.offset().top;
            buttonOffset = buttonOffset - 600; // trigger 600px before button is in view so that loading begins preemptively somewhat before the user has finished scrolling to the bottom
            var buttonHeight = $infiniteScrollButton.height();
            var windowHeight = $(window).height();
            var windowScrollTop = $(window).scrollTop();
            if (buttonOffset < windowHeight + windowScrollTop && buttonOffset + buttonHeight > windowScrollTop) {
                $infiniteScrollButton.trigger('click'); // trigger same behavior as if the user clicked the show more button
            }
        }
    }
    if ($('.infinite-scroll-div.auto-infinite-scroll').length) { // if auto infinite scroll is enabled
        $(window).on('scroll resize', _.throttle(autoInfiniteScroll, 200));
    }

    initializeEvents();
    brands.init();
    placeRefinements();

    window.onpopstate = function() {
        // Not necessary on category landing pages
        if (!$('.pt_categorylanding').length) {
            updateProductListing(document.location.href, true);
        }
    };

    var previousWindowWidth = $(window).width();
    $(window).on('resize', _.throttle(e => {
        var newWindowWidth = $(window).width();
        if (newWindowWidth !== previousWindowWidth) {
            previousWindowWidth = newWindowWidth;
            placeRefinements(e);
        }
    }, 100));
};
