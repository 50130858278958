'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    productSet = require('./productSet'),
    variant = require('./variant'),
    pdpGiftcard = require('./giftcard'),
    googleStructuredProduct = require('./googlestructuredproduct');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */

//Leaving even though empty for now, because description sounds like we'll use it eventually
function initializeDom() {
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');

    addToCart();
    availability();
    variant();
    image();
    pdpGiftcard();
    productSet();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $('.size-chart-link.modal a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    // SPL-768 - Disable original product schema (requested by PowerReviews)
    // googleStructuredProduct();

}


var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
        $('.js-has-quickview').removeClass('js-has-quickview'); //Essentially override quickview being enabled on the 4 tile carousel
    }
};

module.exports = product;
