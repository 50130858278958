'use strict';
window.monerisPaymentTimeout = window.monerisPaymentTimeout || null;
function doMonerisSubmit () {
    try {
        $('#billingSubmitButton').prop('disabled', true);
        window.monerisPaymentTimeout = setTimeout(function () {
            // eslint-disable-next-line no-console
            console.warn('Timeout Warning: Moneris Payment Submission Process taking much longer than expected!');
            // timeout error - re-enable checkout button
            $('#billingSubmitButton').prop('disabled', false);
        }, 9000);
        var $monFrame = document.getElementById('monerisFrame');
        var monFrameRef;
        if (typeof $monFrame === 'object' && $monFrame !== null && 'contentWindow' in $monFrame && window.SitePreferences && 'MONERIS_HT_URL' in window.SitePreferences) {
            monFrameRef = $monFrame.contentWindow;
            monFrameRef.postMessage('tokenize', window.SitePreferences.MONERIS_HT_URL);
        }
    } catch (err) {
        console.error('An error occurred during doMonerisSubmit. Error:' + err.message);
    }
    return false;
}

function respMsg(e) {
    // ignore messages from other services
    if (e.origin.indexOf('moneris') === -1) {
        return;
    }

    var $checkoutForm = $('.checkout-billing');
    var respData = eval("(" + e.data + ")");

    // on success the token is in the field 'respData.dataKey'
    var dataKey = respData.dataKey;
    // Response Code
    // 001 approved
    // 940 Invalid profile id (on tokenization request)
    // 941 Error generating token
    // 942 Invalid Profile ID, or source URL
    // 943 Card data is invalid (not numeric, fails mod10, we will remove spaces)
    // 944 Invalid expiration date (mmyy, must be current month or in the future)
    // 945 Invalid CVD data (not 3-4 digits)
    var responseCode = respData.responseCode;
    var bin = respData.bin;
    var errorMsg = respData.errorMessage;
    // This is only a valid Moneris response if there is a dataKey or a responseCode.
    $('input[name$="monerisToken"]').val(dataKey);
    $('input[name$="monerisCardBin"]').val(bin);
    $('input[name$="monerisResponseCode"]').val(responseCode);
    $('input[name$="monerisErrorMessage"]').val(errorMsg);
    
    var responseMessages = $('.ccn-error').data('messages');

    if (responseCode !== '001') {
        $('.ccn-error').text(responseMessages[responseCode]);
        $('.ccn-error').removeClass('d-none');
    } else if (!$('.ccn-error').hasClass('d-none')){
        $('.ccn-error').addClass('d-none');
    }

    if (window.monerisPaymentTimeout) {
        window.clearTimeout(window.monerisPaymentTimeout);
    }
    $('#billingSubmitButton').prop('disabled', false);
    if (dataKey) {
        $checkoutForm.submit();
        return true;
    } else {
        return false;
    }
}

exports.doMonerisSubmit = doMonerisSubmit;
exports.respMsg = respMsg;
