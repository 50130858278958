'use-strict';

const _ = require('lodash');

module.exports = {

    init() {

        $('body')
        .on('click', '.js-qty__btn', e => {
            const $currentTarget = $(e.currentTarget);
            const $currentInput = $currentTarget.closest('.js-qty').find('.js-qty__input');

            // sync all inputs
            $currentInput
                .val((_.toInteger($currentInput.val()) + _.toInteger($currentTarget.val())))
                .change();
        })
        .on('change', '.js-qty__input', e => {
            // sync inputs and force to positive integer values
            const $currentInput = $(e.currentTarget);
            const min = _.toInteger($currentInput.attr('min'));
            const max = _.toInteger($currentInput.attr('max'));
            let newVal = Math.abs( _.toInteger($currentInput.val()) );

            // enforce min and max values
            newVal = newVal <= max ? ( newVal >= min ? newVal : min ) : max;

            $currentInput.val(newVal);

        });

    }

};
