'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    progress = require('../progress'),
    cartStoreInventory = require('../storeinventory/cart'),
    page = require('../page');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    $('button[id*=applyprice]').on('click', function (e) {
        e.preventDefault();
        var reason, uuid, standardPrice, unitPrice, url, pid;
        pid = $(this).closest('.oob-table').data('pid');
        uuid = this.name.split('-')[1];
        if ($('#select-reason-'+uuid).val() === "") {
            $('#select-reason-'+uuid).removeClass('valid');
            $('#select-reason-'+uuid).addClass('invalid');
            $('#select-reason-'+uuid).attr('aria-invalid', 'true');
            reason = "";
            return;
        } else {
            $('#select-reason-'+uuid).removeClass('invalid');
            $('#select-reason-'+uuid).addClass('valid');
            $('#select-reason-'+uuid).attr('aria-invalid', 'false');
            reason = $('#select-reason-'+uuid).val();
        }
        if ($('#unitprice-'+uuid).val() === "") {
            unitPrice = $('#unitprice-'+uuid).attr('max');
            standardPrice = $('#unitprice-'+uuid).data('standardPrice');
        } else {
            $('#unitprice-'+uuid).removeClass('invalid');
            $('#unitprice-'+uuid).addClass('valid');
            $('#unitprice-'+uuid).attr('aria-invalid', 'false');
            unitPrice = $('#unitprice-'+uuid).val();
            standardPrice = $('#unitprice-'+uuid).data('standardPrice');
        }
        url = this.dataset.url;
        $.ajax(url, {
            type:'POST',
            data: {
                pid: pid,
                uuid: uuid,
                reason: reason,
                unitPrice: unitPrice,
                standardPrice: standardPrice            },
            dataType: 'json',
            success:function(response){
                if (!response.success) {
                    alert(response.message);
                    progress.hide();
                    reinit();
                } else {
                    progress.hide();
                    page.redirect(Urls.cartShow);
                }
            },
            error: function (e) {
                alert("There was some problem with the request. Please try again");
                progress.hide();
                reinit();
            }
        });
    });
    $('select[id*=select-reason]').on('change', function(){
        var new_selection = $(this).find('option:selected');
        $(this).find('option').not(new_selection).removeAttr('selected');
        new_selection.attr("selected",true);
        $(this).removeClass('invalid');
    });
    $('#cart-items-form').on('click', '.item-edit-details > a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart',
            className:'hide-find-store-button'
        });
    });
    $('#cart-items-form, .bonus-item-promo').on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    });

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });
    $('.quantity input').on('change', function() {
        $(this).parent().siblings('.update-cart').removeClass('d-none');
        var groupid = $(this).data('groupid');
        var groupValue = $(this).val();
        $('.'+groupid).val(groupValue);
    });

    function stickyCheckoutButton() {
        let $checkoutButtonWrapper = $('.checkout-button-submit-wrapper');
        let $returnToTop = $('#return-to-top');
        if ( $('.cart-actions').offset().top > ($(window).scrollTop() + $(window).height() - 20)) {
            $checkoutButtonWrapper.addClass('sticky');
            if ($returnToTop.length) {
                $returnToTop.addClass('has-sticky-checkout-btn');
            }
        } else {
            $checkoutButtonWrapper.removeClass('sticky');
            if ($returnToTop.length) {
                $returnToTop.removeClass('has-sticky-checkout-btn');
            }
        }
    }
    $(window).on('scroll resize', stickyCheckoutButton);
    //initial call after page load
    setTimeout(stickyCheckoutButton, 1000);
}
/**
* @private
* @function
* @description Initialize additional functionality related with PayPal integration
*/
function initPaypalFunctionality() {
    var dialog = require('../dialog');
    var $expressButton = $('.js_paypal_start_ba_checkout');

    $expressButton.on('click', function() {
        if($expressButton.data('is-address-exist') === true) {
            return true;
        }
        dialog.open({
            url: $expressButton.data('edit-address-url'),
            options: {
                title: $expressButton.data('edit-address-title'),
                open: initEditDefaultShippingAddressForm
            },
        });
        return false;
    });

    function initEditDefaultShippingAddressForm() {
        var $form = $('#paypalEditDefaultShippingAddress');
        $form.on('click', '.apply-button', function() {
            if (!$form.valid()) {
                return false;
            }
            var applyName = $form.find('.apply-button').attr('name');
            var options = {
                url: $form.attr('action'),
                data: $form.serialize() + '&' + applyName + '=x',
                type: 'POST'
            };
            $.ajax(options).done(function(data) {
                if (typeof(data) !== 'string') {
                    if(data.success) {
                        dialog.close();
                        window.location = $expressButton.attr('href');
                    } else {
                        window.alert(data.message);
                        return false;
                    }
                } else {
                    $('#dialog-container').html(data);
                    initEditDefaultShippingAddressForm();
                }
            });
            return false;
        });
        $form.on('click', '.cancel-button, .close-button', function() {
            dialog.close();
            return false;
        });
        $('#paypalSelectSavedAddress').change(function() {
            var data = $(this).val();
            try {
                data = JSON.parse(data);

                for(var addressName in data) {
                    var val = data[addressName];
                    if(typeof val === 'string') {
                        val = val.replace(/\^/g,"'");
                    }
                    $('#dwfrm_profile_address_' + addressName).val(val);
                }
            } catch(e) {
                $form.find('input:text').val('');
                $form.find('select').val('');
            }
        });
    }
}

function reinit() {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
    initPaypalFunctionality();
}

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
    initPaypalFunctionality();
};
