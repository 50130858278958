'use strict';

var giftcard = require('../../giftcard');

var $pdpMain = $('#pdpMain');

module.exports = function() {
    $pdpMain.on('click', '#CheckBalanceButton', function (e) {
        var $giftCertCode = $('input[name$="_cardnumber"]');
        var $pin = $('input[name$="_cardpin"]');

        e.preventDefault();
        var $balance = $('.balance');
        var $error = $('.gift-cert-balance-submit').siblings('.form-row.error');
        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            var error = $balance.find('span.error');
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), $pin.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                return;
            }
            if(data.giftCertificate.error) {
                $balance.html('');

                if($error.length) {
                    $error.html(data.giftCertificate.errormsg);
                }
                else {
                    $('.gift-cert-balance-submit').after(`<div class="form-row error">${data.giftCertificate.errormsg}</div>`);
                }
            }
            else {
                if($error.length) {
                    $error.html('');
                }
                $balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance).removeClass('error').addClass('success');
            }
        });
    });
};
